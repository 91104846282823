import { APPLICATION_VERSION } from '@keyliving/shared-types';

import type { CloverCollectionId, CloverStepIds } from './CloverSequence/constants';
import { CLOVER_SEQUENCE } from './CloverSequence/constants';
import type { KeyCollectionId, KeyStepIds } from './KeySequence/constants';
import { KEY_SEQUENCE } from './KeySequence/constants';

/**
 * NOTE: This is the best I could do for now. I tried, type CollectionIds = keyof typeof SEQUENCES[ORGS];
 * but it only returned "overview" which was the overlapping key in both sequences. This
 * won't change very often so a union should work for now.
 *
 * NOTE: I would prefer to put the types in the ./types.ts file but that would then put the
 * burdon of updating two files everytime we add a new org on the developer to know that.
 * With them in one place this reduces the chance for error.
 */
export type CollectionId = KeyCollectionId | CloverCollectionId;
export type StepId = KeyStepIds | CloverStepIds;

// export interface Sequence extends Record<string, Collection> {
//     overview: Collection;
// }

// export const SEQUENCES: Record<ORGS, Sequence> = {
export const SEQUENCES = {
    clover: CLOVER_SEQUENCE,
    key: KEY_SEQUENCE,
};

export const LATEST_APPLICATION_VERSION = APPLICATION_VERSION.V2;
