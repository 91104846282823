import { SEQUENCES } from '../components/sequence/constants';
import { Collection } from '../components/sequence/models';

/**
 * Get an array of unique collection ids across ALL sequences
 *
 * @returns Array of collection ids
 */
export default function getUniqueCollectionIds(): string[] {
    const collections = Object.values(SEQUENCES).reduce((accumulator, collection) => {
        return {
            ...accumulator,
            ...collection,
        };
    }, {} as Record<string, Collection>);

    return Object.keys(collections);
}
