import { CollectionId } from '../components/sequence/constants';
import getUniqueCollectionIds from './getUniqueCollectionIds';

/**
 * Type guard helper
 *
 * @param {unknown} str String to validate
 * @returns True or false based on if the string is in the collectionIds array
 */
export default function isCollectionId(str: unknown): str is CollectionId {
    if (typeof str !== 'string') {
        return false;
    }

    const collectionIds = getUniqueCollectionIds();

    return collectionIds.includes(str);
}
