import { route, string as typeString } from 'react-router-typesafe-routes/dom';
import { zod } from 'react-router-typesafe-routes/zod';
import { boolean, string } from 'zod';

import { validCollectionId, validOrg, validStepId } from './lib';

/**
 * NOTE: Validation helpers catch parsing and validation errors
 * and replace values with undefined.
 *
 * e.g. zod(string().email()) - Requires the email passed in
 * the url to be a valid email. Otherwise the value
 * returned by useTypedSearchParams for email
 * will be undefined
 */

export const URLS = {
    Home: route(''),
    Collection: route(
        ':org/:applicationId/:collectionId',
        {
            params: {
                org: typeString(validOrg).defined(),
                collectionId: typeString(validCollectionId).defined(),
                applicationId: zod(string().uuid()).defined(),
            },
        },
        {
            Step: route(':stepId', {
                params: {
                    stepId: typeString(validStepId),
                },
            }),
        }
    ),
    ForgotPassword: route(
        'forgot-password',
        {
            searchParams: {
                email: zod(string().email()),
            },
        },
        {
            Success: route('success'),
        }
    ),
    Login: route('login', {
        searchParams: {
            token: zod(string()),
            email: zod(string().email()),
        },
    }),
    NewPassword: route('new-password/:token'),
    Register: route('register', {
        searchParams: {
            email: zod(string()),
            firstName: zod(string()),
            lastName: zod(string()),
            performSoftCheckOnly: zod(boolean()).default(false),
            org: typeString(validOrg), // no default passed, we desire validation failure if org not provided
            applicationFormId: zod(string().uuid()),
        },
    }),
};
