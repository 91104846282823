import { number, object, string } from 'zod';

import type { ResponseError } from '../redux/modules/api/lib';

/**
 * Type guard helper function to determine if passed value
 * is a ResponseError.
 */
export function isResponseError(obj: unknown): obj is ResponseError {
    const schema = object({
        status: number(),
        message: string().min(1),
    });

    try {
        schema.parse(obj);
        return true;
    } catch (error) {
        return false;
    }
}
