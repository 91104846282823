import { StepId } from '../components/sequence/constants';
import getUniqueStepIds from './getUniqueStepIds';

/**
 * Type guard helper
 *
 * @param {unknown} str String to validate
 * @returns True or false based on if the string is in the stepIds array
 */
export default function isStepId(str: unknown): str is StepId {
    if (typeof str !== 'string') {
        return false;
    }

    const stepIds = getUniqueStepIds();

    return stepIds.includes(str);
}
