import { OnboardingApplication, OnboardingApplicationV1 } from '@keyliving/shared-types';

import { getApplicationVersion } from './getApplicationVersion';

/**
 * Type guard for application V1
 *
 * @param application The application in question
 * @returns Narrowed application types union
 */
export default function isOnboardingApplicationV1(
    application: OnboardingApplication
): application is OnboardingApplicationV1 {
    const versionNumber = getApplicationVersion(application);

    if (versionNumber && Number(versionNumber) === 1) {
        return true;
    }

    return false;
}
