import { useContext } from 'react';

import SeqenceContext from '../components/sequence/Context';

export default function useSequence() {
    const context = useContext(SeqenceContext);

    if (!context) {
        throw new Error('useSequence must be used inside the Seqence Context');
    }

    return context;
}
