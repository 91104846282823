import { useGlobalAuth } from '@keyliving/component-lib';
import { Claims } from '@keyliving/shared-types';
import { isTokenExpired } from '@keyliving/utils';
import { captureException } from '@sentry/react';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';

import FullScreenFallbackLayout from '../../components/layout/FullScreenFallbackLayout';
import { useAppDispatch } from '../../hooks';
import {
    setAuthDetails,
    setCurrentUser,
    useLazyMeQuery,
    useLazyVerifyAuthTokenQuery,
} from '../../redux/modules/auth';
import { URLS } from '../../routes/urls';

/**
 * Login page is not implemented in the individual clients. Login is handled by the Accounts
 * client now. Keeping the login page to handle user impersonations.
 */
export default function Login() {
    const navigate = useNavigate();
    const { redirectToAuth, token: adminToken } = useGlobalAuth();
    const [verifyAuthToken] = useLazyVerifyAuthTokenQuery();
    const [getCurrentUserDetails] = useLazyMeQuery();
    const dispatch = useAppDispatch();
    const [{ token: impersonationToken }, setSearchParams] = useTypedSearchParams(URLS.Login);

    const verifyToken = useCallback(
        async (token: string): Promise<Claims> => {
            try {
                // Verify the token...
                const claims = await verifyAuthToken(token).unwrap();

                // ...token expiry should be handled by the api, but to be safe...
                const isExpired = isTokenExpired(claims.exp);

                if (isExpired) {
                    throw new Error('Token expired');
                }

                return Promise.resolve(claims);
            } catch (error) {
                return Promise.reject('Token has expired');
            }
        },
        [verifyAuthToken]
    );

    /**
     * Setup state as the impersonated user and save impersonation token to session storage.
     *
     * @param adminToken Token of the user who is logged in from the auth cookie
     * @param impersonationToken Token of the user we are trying to impersonate passed via the url
     */
    const handleImpersonateUser = useCallback(
        async (adminToken: string, impersonationToken: string) => {
            try {
                // Verify the tokens
                const [adminClaims, impersonationClaims] = await Promise.all([
                    verifyToken(adminToken),
                    verifyToken(impersonationToken),
                ]);

                const adminId = adminClaims.id;
                const impersonatorId = impersonationClaims.act?.sub;

                /**
                 * We've tried to use a token that we aren't authenticated to use
                 */
                if (!impersonatorId || impersonatorId !== adminId) {
                    /**
                     * Maybe a low chance this is helpful but might alert us if someone
                     * is trying to do something nefarious.
                     */
                    captureException(
                        new Error(`[Impersonation]: Unauthorized to impersonate user`),
                        {
                            tags: {
                                page: 'Impersonation',
                            },
                            extra: {
                                // User who is logged in via auth cookie
                                adminUserId: adminId,
                                // The "actor" on the impersonation token
                                impersonatorId,
                                // User the impersonation token is for
                                impersonatedUserId: impersonationClaims.id,
                            },
                        }
                    );

                    throw new Error(
                        'The actor user.id on the impersonation token does not match the user.id of the person who is trying to impersonate them'
                    );
                }

                // Save the token to session storage
                sessionStorage.setItem('impersonationToken', impersonationToken);

                dispatch(
                    setAuthDetails({
                        claims: impersonationClaims,
                        token: impersonationToken,
                        user: null,
                    })
                );

                // Get the user - save user details
                const user = await getCurrentUserDetails().unwrap();
                dispatch(setCurrentUser(user));

                navigate('/', { replace: true });
            } catch (error) {
                // Remove the token
                setSearchParams((params) => {
                    return {
                        ...params,
                        token: undefined,
                    };
                });

                // Send them to the accounts client to login
                redirectToAuth({
                    relativeAuthRoute: '/magic-link',
                    searchParams: {
                        redirectUrl: window.location.origin,
                    },
                });
            }
        },
        [dispatch, getCurrentUserDetails, navigate, redirectToAuth, setSearchParams, verifyToken]
    );

    useEffect(() => {
        // We need to be logged in AND have an impersonation token
        if (impersonationToken && adminToken) {
            handleImpersonateUser(adminToken, impersonationToken);
        } else {
            // Send them to the accounts client to login
            redirectToAuth({
                relativeAuthRoute: '/magic-link',
                searchParams: {
                    redirectUrl: window.location.origin,
                },
            });
        }
    }, [adminToken, handleImpersonateUser, impersonationToken, redirectToAuth]);

    return <FullScreenFallbackLayout isLoading />;
}
