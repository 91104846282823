import { ToastProvider } from '@keyliving/component-lib';
import { StrictMode, useEffect } from 'react';
import { render } from 'react-dom';
import {
    BrowserRouter,
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
// import reportWebVitals from './reportWebVitals';

if (process.env.NODE_ENV === 'production') {
    import('@sentry/react').then((sentry) => {
        sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            integrations: [
                sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                }),
                sentry.replayIntegration({
                    blockAllMedia: false,
                    // Starting with v8, the options unblock and unmask do not add default DOM selectors anymore.
                    unmask: ['.sentry-unmask', '[data-sentry-unmask]'],
                }),
            ],
            // Performance Monitoring
            tracesSampleRate: 0.25, // Capture 25% of the transactions
            // Session Replay
            replaysSessionSampleRate: 0.05, // This sets the sample rate at 5%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
            tracePropagationTargets: process.env.REACT_APP_API_SERVICE_ENDPOINT
                ? [process.env.REACT_APP_API_SERVICE_ENDPOINT]
                : undefined,
        });
    });
}

if (process.env.REACT_APP_MOCK_DATA === 'true') {
    const { worker } = require('./mocks/browser');
    worker.start({
        onUnhandledRequest: 'warn',
    });
}

render(
    <StrictMode>
        <ErrorBoundary>
            <ToastProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ToastProvider>
        </ErrorBoundary>
    </StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
