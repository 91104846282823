import {
    APPLICATION_VERSION,
    DOCUMENT_REQUEST_STATUS,
    OnboardingApplication,
} from '@keyliving/shared-types';
import { lazy } from 'react';
import { array, object, string } from 'zod';

import { documentRequestService } from '../../../redux/modules/documentRequest';
import { getApplicationVersion } from '../../../utils/getApplicationVersion';
import isOnboardingApplicationV1 from '../../../utils/isOnboardingApplicationV1';
import { store } from '../../HydrationGate';
import { Collection } from '../models';

const AddressStep = lazy(() => import('./collections/personal/steps/Address'));
const BirthdayStep = lazy(() => import('./collections/personal/steps/Birthday'));
const DocumentUploadStep = lazy(() => import('./collections/documents/steps/Upload'));
const EmploymentStatus = lazy(() => import('./collections/personal/steps/EmploymentStatus'));
const PlaidIdentityStep = lazy(() => import('./collections/identity/steps/Plaid'));
const InviteCoApplicantCollection = lazy(() => import('./collections/invite/Invite'));
const NextStepsCollection = lazy(() => import('./collections/nextSteps/NextSteps'));
const OverviewCollection = lazy(() => import('./collections/overview/Overview'));
const PhoneNumberStep = lazy(() => import('./collections/personal/steps/PhoneNumber'));
const PlaidIncomeStep = lazy(() => import('./collections/finance/steps/Plaid'));
const SocialInsuranceNumberStep = lazy(
    () => import('./collections/personal/steps/SocialInsuranceNumber')
);
const VerifyStep = lazy(() => import('./collections/personal/steps/Verify'));
const WelcomeCollection = lazy(() => import('./collections/welcome/Welcome'));

async function isEditableBasedOnCertn(application: OnboardingApplication | null): Promise<boolean> {
    const certnCreditCheckId = application?.protected_application_data?.credit_check?.creditCheckId;
    /**
     * Certn is considered "started" when we submit a credit check.
     * So, if we have a certn id, then we have
     * started the process with Certn already.
     */
    const certnStarted = !!certnCreditCheckId;

    return !certnStarted;
}

async function isEditablePhoneNumber(application: OnboardingApplication | null): Promise<boolean> {
    const isEditableCertn = await isEditableBasedOnCertn(application);

    if (isEditableCertn) {
        return true;
    }

    /*
     * Required to allow a user to complete an in progress application
     * which has already submitted a credit check
     */
    return !application?.application_data?.personal?.phoneNumber;
}

/**
 * We don't type this object as "Record<string, Step>" because we need to extract
 * the value of "id: 'income'" for our KeyStepIds type. The "steps" property
 * of a Collection is typed as Record<string, Step> though so if we don't
 * conform to the correct structure, we should get an error. "as const"
 * is where the magic happens.
 */
const financeCollectionSteps = {
    plaid: {
        Component: PlaidIncomeStep,
        isComplete: async (application: OnboardingApplication | null): Promise<boolean> => {
            const data = application?.protected_application_data?.plaid;
            const versionNumber = getApplicationVersion(application);

            let schema;

            switch (versionNumber) {
                case APPLICATION_VERSION.V2:
                    schema = object({
                        incomeHistory: array(
                            object({
                                bank_income_id: string(),
                            })
                        ).nonempty(),
                    });
                    break;
                // Using default here to handle V1 case and "none of the above" case as it is more succinct
                default:
                    schema = object({
                        incomeHistory: array(
                            object({
                                bankIncomeId: string(),
                            })
                        ).nonempty(),
                    });
                    break;
            }

            try {
                schema.parse(data);
                return true;
            } catch (error) {
                return false;
            }
        },
        isEditable: async function (application: OnboardingApplication | null): Promise<boolean> {
            /**
             * This doesn't really apply for this step so basing it
             * off isComplete. It's editable if it's NOT complete.
             * Meaning we haven't submited Plaid yet.
             *
             * NOTE: In future, maybe isEditable can be defined as undefined?
             * e.g. isEditable?: (application: OnboardingApplication | null) => boolean;
             */
            return !this.isComplete(application);
        },
        isStarted: async function (application: OnboardingApplication | null): Promise<boolean> {
            return this.isComplete(application);
        },
    },
} as const;

const identityCollectionSteps = {
    'plaid-id-verification': {
        Component: PlaidIdentityStep,
        isComplete: async (application: OnboardingApplication | null): Promise<boolean> => {
            const checkStatus =
                application?.protected_application_data?.plaid_id_verification?.identityVerification
                    ?.documentary_verification?.status;

            if (!checkStatus) {
                return false;
            }

            return checkStatus.toLowerCase() === 'success';
        },
        isEditable: async function (application: OnboardingApplication | null): Promise<boolean> {
            return !this.isComplete(application);
        },
        isStarted: async function (application: OnboardingApplication | null): Promise<boolean> {
            return this.isComplete(application);
        },
    },
} as const;

const documentUploadCollectionSteps = {
    upload: {
        Component: DocumentUploadStep,
        isComplete: async (application: OnboardingApplication | null): Promise<boolean> => {
            if (!application || !store) {
                return false;
            }

            /**
             * If we haven't provided an employment status, then there is no way
             * to create the document requests.
             */
            const employmentComplete = await personalCollectionSteps.employment.isComplete(
                application
            );

            if (!employmentComplete) {
                return false;
            }

            try {
                const documentRequests = await store
                    .dispatch(
                        documentRequestService.endpoints.getApplicantDocumentRequests.initiate(
                            application.applicant_id
                        )
                    )
                    .unwrap();

                // If we haven't requested any documents, there is nothing for them to provide (most likely a V1 application)
                if (documentRequests.length === 0) {
                    return true;
                }

                return documentRequests.every((request) => {
                    return request.status === DOCUMENT_REQUEST_STATUS.FULFILLED;
                });
            } catch (error) {
                return false;
            }
        },
        isEditable: async function (application: OnboardingApplication | null): Promise<boolean> {
            return !this.isComplete(application);
        },
        isStarted: async function (application: OnboardingApplication | null): Promise<boolean> {
            if (!application || !store) {
                return false;
            }

            try {
                const documentRequests = await store
                    .dispatch(
                        documentRequestService.endpoints.getApplicantDocumentRequests.initiate(
                            application.applicant_id
                        )
                    )
                    .unwrap();

                return documentRequests.some((request) => {
                    return request.status === DOCUMENT_REQUEST_STATUS.FULFILLED;
                });
            } catch (error) {
                return false;
            }
        },
    },
} as const;

const personalCollectionSteps = {
    verify: {
        Component: VerifyStep,
        isComplete: async (application: OnboardingApplication | null): Promise<boolean> => {
            const firstName = application?.application_data?.personal?.firstName;
            const lastName = application?.application_data?.personal?.lastName;

            return !!firstName && !!lastName;
        },
        isEditable: isEditableBasedOnCertn,
        isStarted: async function (application: OnboardingApplication | null): Promise<boolean> {
            return this.isComplete(application);
        },
    },
    'phone-number': {
        Component: PhoneNumberStep,
        isComplete: async (application: OnboardingApplication | null): Promise<boolean> => {
            const phoneNumber = application?.application_data?.personal?.phoneNumber;

            return !!phoneNumber;
        },
        isEditable: isEditablePhoneNumber,
        isStarted: async function (application: OnboardingApplication | null): Promise<boolean> {
            return this.isComplete(application);
        },
    },
    address: {
        Component: AddressStep,
        isComplete: async (application: OnboardingApplication | null): Promise<boolean> => {
            const data = application?.application_data?.personal;

            /**
             * NOTE: We can get fancier validating postalcode/zip formats, province/state
             * names and country against country codes but for now, this will work
             */

            const schema = object({
                city: string().min(1),
                street: string().min(1),
                province: string().min(1).max(2),
                country: string().min(1),
                postalCode: string().min(1),
            });

            try {
                schema.parse(data);
                return true;
            } catch (error) {
                return false;
            }
        },
        isEditable: isEditableBasedOnCertn,
        isStarted: async function (application: OnboardingApplication | null): Promise<boolean> {
            return this.isComplete(application);
        },
    },
    birthday: {
        Component: BirthdayStep,
        isComplete: async (application: OnboardingApplication | null): Promise<boolean> => {
            const data = application?.application_data?.personal;

            const schema = object({
                birthDate: object({
                    day: string().min(2),
                    year: string().min(4),
                    month: string().min(2),
                }),
            });

            try {
                schema.parse(data);
                return true;
            } catch (error) {
                return false;
            }
        },
        isEditable: isEditableBasedOnCertn,
        isStarted: async function (application: OnboardingApplication | null): Promise<boolean> {
            return this.isComplete(application);
        },
    },
    employment: {
        Component: EmploymentStatus,
        isComplete: async (application: OnboardingApplication | null): Promise<boolean> => {
            if (!application) {
                return false;
            }

            // If V1, we don't require employmentStatus
            if (isOnboardingApplicationV1(application)) {
                return true;
            }

            const employmentStatus = application.application_data?.personal?.employmentStatus;

            return !!employmentStatus;
        },
        // Not necessary to lock based on certn as we don't send this info but to be consistent with the rest of the collection...
        isEditable: isEditableBasedOnCertn,
        isStarted: async function (application: OnboardingApplication | null): Promise<boolean> {
            return this.isComplete(application);
        },
    },
    'social-insurance-number': {
        Component: SocialInsuranceNumberStep,
        isComplete: async (application: OnboardingApplication | null): Promise<boolean> => {
            const legalSchema = object({
                consentHardCredit: string().datetime(),
            });

            const creditCheckId =
                application?.protected_application_data?.credit_check?.creditCheckId;

            const creditCheckIdSchema = string().uuid();

            const data = application?.application_data?.personal;

            const schema = object({
                socialInsuranceNumber: string().min(9).max(9),
            });

            try {
                schema.parse(data);
                creditCheckIdSchema.parse(creditCheckId);
                const isComplete = legalSchema.safeParse(
                    application?.application_data?.legal
                ).success;

                return isComplete;
            } catch (error) {
                return false;
            }
        },
        isEditable: isEditableBasedOnCertn,
        isStarted: async function (application: OnboardingApplication | null): Promise<boolean> {
            return this.isComplete(application);
        },
    },
} as const;

const welcomeCollection = new Collection({ Component: WelcomeCollection, hidden: true });

const overviewCollection = new Collection({ Component: OverviewCollection, hidden: true });

const personalCollection = new Collection({
    steps: personalCollectionSteps,
});

const financeCollection = new Collection({
    steps: financeCollectionSteps,
});

const identityCollection = new Collection({
    steps: identityCollectionSteps,
    async prerequisiteCompleted(application): Promise<boolean> {
        if (!application) {
            return false;
        }

        return personalCollection.isComplete(application);
    },
});

const documentUploadCollection = new Collection({
    steps: documentUploadCollectionSteps,
    async prerequisiteCompleted(application): Promise<boolean> {
        if (!application) {
            return false;
        }

        /**
         * We need them to provide their employment status in the personal
         * collection before we can generate the document requests.
         */
        return personalCollection.isComplete(application);
    },
});

const inviteCollection = new Collection({
    Component: InviteCoApplicantCollection,
    hidden: true,
});

const nextStepsCollection = new Collection({
    Component: NextStepsCollection,
    hidden: true,
});

const allSteps = {
    ...financeCollectionSteps,
    ...identityCollectionSteps,
    ...personalCollectionSteps,
    ...documentUploadCollectionSteps,
};

export const KEY_SEQUENCE = {
    welcome: welcomeCollection,
    personal: personalCollection,
    finance: financeCollection,
    identity: identityCollection,
    documents: documentUploadCollection,
    overview: overviewCollection,
    invite: inviteCollection,
    'next-steps': nextStepsCollection,
} as const;

export type KeyCollectionId = keyof typeof KEY_SEQUENCE;
export type KeyStepIds = keyof typeof allSteps;
