import { Application } from '@keyliving/shared-types';

export function getApplicationVersion(application: Application | null) {
    if (!application) {
        throw new Error('Application unavailable');
    }

    if (
        application?.application_data &&
        'version' in application.application_data &&
        application.application_data?.version?.versionNumber
    ) {
        return application.application_data.version.versionNumber;
    }

    return undefined; // unlock client applications do not have a version number
}
