import { OnboardingApplication } from '@keyliving/shared-types';
import { LazyExoticComponent } from 'react';

import { StepId } from './constants';
import { CollectionProps, CollectionStatus, Step } from './types';

interface CollectionConstructorParams {
    Component?: LazyExoticComponent<(props: CollectionProps) => JSX.Element>;
    /**
     * If a collection is hidden, we dont want to show it in any
     * lists or when we are determining what the next
     * collection id should be
     */
    hidden?: boolean;
    /**
     * If there are actions that need to be performed or other collections
     * that need to be completed first before going through this one.
     */
    prerequisiteCompleted?: (application: OnboardingApplication | null) => Promise<boolean>;
    steps?: Record<string, Step>;
}

export class Collection {
    readonly steps?: Record<string, Step>;
    readonly Component?: LazyExoticComponent<(props: CollectionProps) => JSX.Element>;
    readonly hidden: boolean;
    readonly prerequisiteCompleted: (application: OnboardingApplication | null) => Promise<boolean>;

    public constructor({
        Component,
        hidden = false,
        prerequisiteCompleted = () => Promise.resolve(true),
        steps,
    }: CollectionConstructorParams) {
        this.Component = Component;
        this.steps = steps;
        this.hidden = hidden;
        this.prerequisiteCompleted = prerequisiteCompleted;
    }

    /**
     * Get an array of all the step ids
     */
    get stepIds(): string[] {
        if (!this.steps) {
            return [];
        }

        return Object.keys(this.steps);
    }

    /**
     * Get the current status of the collection.
     *
     * @param {OnboardingApplication | null} application
     * @returns The status of the collection
     */
    async getStatus(application: OnboardingApplication | null): Promise<CollectionStatus> {
        if (await this.isComplete(application)) {
            return CollectionStatus.COMPLETED;
        }

        if (await this.isStarted(application)) {
            return CollectionStatus.IN_PROGRESS;
        }

        return CollectionStatus.NOT_STARTED;
    }

    /**
     * If any of the steps have been started, then the collection
     * has been started.
     */
    async isStarted(application: OnboardingApplication | null): Promise<boolean> {
        const steps = this.steps ? Object.values(this.steps) : [];

        try {
            const results = await Promise.all(steps.map((step) => step.isStarted(application)));
            return results.some((isStarted) => isStarted);
        } catch (error) {
            // None of the steps should throw but to be safe...
            return false;
        }
    }

    /**
     * If all steps have been completed then a collection is
     * considered complete.
     */
    async isComplete(application: OnboardingApplication | null): Promise<boolean> {
        // If we don't have any steps then there is nothing to complete
        if (!this.steps) {
            return true;
        }

        try {
            const results = await Promise.all(
                Object.values(this.steps).map((step) => step.isComplete(application))
            );

            return results.every((result) => result === true);
        } catch (error) {
            // None of the steps should throw but to be safe...
            return false;
        }
    }

    /**
     * Is the step with the given id the last step in the collection.
     *
     * @param {string} stepId
     * @returns True or False
     */
    isLastStep(stepId: string): boolean {
        if (!this.steps) {
            return false;
        }

        const lastStepId = this.stepIds[this.stepIds.length - 1];

        if (stepId === lastStepId) {
            return true;
        }

        return false;
    }

    /**
     * If a valid step id, get the step for the given id.
     *
     * @param {string} id The id of the step you want to retrieve
     * @returns the step, given there is a step with that id
     */
    getStepById(id: string): Step | null {
        if (!this.steps || !this.stepIds.includes(id)) {
            return null;
        }

        return this.steps[id];
    }

    /**
     * Given a step id, get the next step id in the collection.
     *
     * @param {string} currentStepId the id of the step you want to find the one after
     * @returns the next step id
     */
    getNextStepId(currentStepId: string): StepId | null {
        const currentStepIndex = this.stepIds.findIndex((id) => id === currentStepId);

        if (currentStepIndex < 0 || this.isLastStep(currentStepId)) {
            return null;
        }

        const nextStepId = this.stepIds[currentStepIndex + 1];

        return nextStepId as StepId;
    }

    /**
     * Get the id if the first step
     */
    get firstStepId(): StepId | null {
        if (this.stepIds.length > 0) {
            return this.stepIds[0] as StepId;
        }

        return null;
    }
}
