import FullScreenFallbackLayout from '../components/layout/FullScreenFallbackLayout';
import Whoops from '../components/Whoops';

export default function NoMatch() {
    return (
        <FullScreenFallbackLayout>
            <Whoops status={404} />
        </FullScreenFallbackLayout>
    );
}
