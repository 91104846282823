import { Anchor, useGlobalAuth } from '@keyliving/component-lib';
import { APPLICATION_VERSION, ORGS } from '@keyliving/shared-types';
import { isOrg } from '@keyliving/utils';
import { captureException } from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTypedSearchParams } from 'react-router-typesafe-routes/dom';

import FullScreenFallbackLayout from '../../components/layout/FullScreenFallbackLayout';
import {
    CollectionId,
    LATEST_APPLICATION_VERSION,
    SEQUENCES,
} from '../../components/sequence/constants';
import Whoops from '../../components/Whoops';
import { SUPPORT_EMAIL } from '../../constants';
import { useAnalytics, useAuth } from '../../hooks';
import {
    useCreateApplicationMutation,
    useUpdateApplicationDetailsMutation,
} from '../../redux/modules/application';
import { URLS } from '../../routes/urls';
import { isResponseError } from '../../utils/isResponseError';

interface HandleRegisterParams {
    email: string;
    firstName?: string;
    lastName?: string;
    org: string;
    performSoftCheckOnly?: boolean;
}

interface GetOrCreateApplicationParams {
    isPrimaryApplicant?: boolean;
    org: ORGS;
    userId: string;
}

interface GetOrCreateApplicationRequestBody {
    applicationFormId?: string;
    isPrimaryApplicant?: boolean;
    orgCode: ORGS;
    userId: string;
    versionNumber: APPLICATION_VERSION;
}

interface NavigateDirectlyToFirstCollectionParams {
    applicationId: string;
    org: ORGS;
}

export default function Register() {
    const [sentryEventId, setSentryEventId] = useState<string | null>(null);
    const { redirectToAuth, setToken: setAuthToken } = useGlobalAuth();
    const [
        {
            applicationFormId,
            email: unprocessedEmail,
            firstName,
            lastName,
            org,
            performSoftCheckOnly,
        },
    ] = useTypedSearchParams(URLS.Register);

    // This replace call is to work around a hubspot bug here:
    // https://community.hubspot.com/t5/CMS-Development/URL-Encoding-issue-on-custom-url-with-email/m-p/258675/highlight/true#M11430

    const email = unprocessedEmail?.replace(' ', '+');
    const { isLoading: registering, isLoggedIn, passwordlessRegistration, user } = useAuth();
    const navigate = useNavigate();
    const { trackEvent } = useAnalytics();
    const [createApplication, { isLoading: creatingApplication }] = useCreateApplicationMutation();
    const [updateApplicationDetails, { isLoading: updatingApplication }] =
        useUpdateApplicationDetailsMutation();

    const isLoading = registering || creatingApplication || updatingApplication;
    const authUsersEmail = user?.email;
    const authUsersId = user?.id;

    async function getOrCreateApplication({
        isPrimaryApplicant,
        org,
        userId,
    }: GetOrCreateApplicationParams) {
        const requestBody: GetOrCreateApplicationRequestBody = {
            userId,
            orgCode: org,
            versionNumber: LATEST_APPLICATION_VERSION,
        };

        if (isPrimaryApplicant !== undefined) {
            requestBody.isPrimaryApplicant = isPrimaryApplicant;
        }

        if (applicationFormId) {
            requestBody.applicationFormId = applicationFormId;
        }

        let application;
        try {
            application = await createApplication(requestBody).unwrap();
        } catch (error) {
            // fatal error for registration
            return null;
        }

        let updatedApplication;
        try {
            updatedApplication = await updateApplicationDetails({
                category: 'personal',
                details: {
                    firstName,
                    lastName,
                },
                applicationFormId: application.id,
                userId,
                variant: org,
                versionNumber:
                    application?.application_data?.version?.versionNumber ||
                    LATEST_APPLICATION_VERSION,
            }).unwrap();
        } catch (error) {
            // Non-fatal error, these details will be provided in future steps
            return application;
        }

        return updatedApplication;
    }
    async function navigateDirectlyToFirstCollection({
        applicationId,
        org,
    }: NavigateDirectlyToFirstCollectionParams) {
        const sequence = SEQUENCES[org];
        const [firstCollectionId] = Object.keys(sequence);
        navigate(
            URLS.Collection.buildPath({
                org,
                collectionId: firstCollectionId as CollectionId,
                applicationId,
            }),
            { replace: true }
        );
    }

    const handlePasswordlessRegister = useCallback(
        async ({ email, firstName, lastName, org }: HandleRegisterParams): Promise<void> => {
            const combinedName = [firstName, lastName].join(' ').trim();
            const fullName = combinedName.length ? combinedName : null;

            try {
                const { claims, token, user } = await passwordlessRegistration({
                    email,
                    fullName,
                    org,
                });

                // Of course there is a user, we just created one. But to make TS happy...
                if (user && isOrg(org)) {
                    // Create an application
                    const application = await getOrCreateApplication({
                        isPrimaryApplicant: !applicationFormId,
                        org,
                        userId: user.id,
                    });

                    /**
                     * Wait to set the auth token until we have successfully created the application
                     */
                    setAuthToken(token, {
                        expires: new Date(claims.exp * 1_000), // num milliseconds
                    });

                    trackEvent('passwordless_registration');

                    if (application) {
                        await navigateDirectlyToFirstCollection({
                            applicationId: application.id,
                            org,
                        });
                    }
                }
            } catch (error) {
                // If account exists already...
                if (isResponseError(error) && error.status === 409) {
                    // ...check if there is already a user logged in with that email
                    if (isLoggedIn && authUsersId && isOrg(org) && authUsersEmail === email) {
                        // get or create application
                        const application = await getOrCreateApplication({
                            isPrimaryApplicant: !applicationFormId,
                            org,
                            userId: authUsersId,
                        });

                        if (application) {
                            await navigateDirectlyToFirstCollection({
                                applicationId: application.id,
                                org,
                            });
                        }

                        return;
                    }

                    // ...redirect to login with email param in url to autofill input
                    redirectToAuth({
                        relativeAuthRoute: '/magic-link',
                        searchParams: {
                            redirectUrl: window.location.href, // return to this page to get or create an application
                            email,
                            org,
                        },
                    });

                    return;
                }

                const eventId = captureException(new Error(`[Register]: Couldn't register user`), {
                    tags: {
                        page: 'register',
                    },
                    extra: {
                        email,
                        firstName,
                        lastName,
                        org,
                    },
                });

                setSentryEventId(eventId);

                /**
                 * Just swallow the error for now to show the register form.
                 */
                // return Promise.reject(error);
                return;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            authUsersId,
            authUsersEmail,
            isLoggedIn,
            passwordlessRegistration,
            redirectToAuth,
            setAuthToken,
            trackEvent,
        ]
    );

    useEffect(() => {
        if (email && org) {
            handlePasswordlessRegister({
                email,
                org,
                firstName,
                lastName,
            });
        }

        window.sessionStorage.setItem('behaviorFlags', JSON.stringify({ performSoftCheckOnly }));

        // We only want this to run once on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isLoading) {
        return <FullScreenFallbackLayout isLoading />;
    }

    let href = `mailto:${SUPPORT_EMAIL}?subject=Error during registration`;

    if (sentryEventId) {
        href += `&body=Incident reference number: ${sentryEventId}`;
    }

    return (
        <FullScreenFallbackLayout>
            <Whoops message="Looks like we are having trouble">
                <div className="content">
                    <p>
                        Please contact support at{' '}
                        <strong>
                            <Anchor className="text-link " href={href}>
                                {SUPPORT_EMAIL}
                            </Anchor>
                        </strong>
                    </p>
                </div>
            </Whoops>
        </FullScreenFallbackLayout>
    );
}
