import { OnboardingApplication } from '@keyliving/shared-types';
import { LazyExoticComponent } from 'react';

import { Collection } from './models';

export type Sequence = Record<string, Collection>;

export interface Step {
    Component: LazyExoticComponent<(props: StepProps) => JSX.Element>;
    isComplete: (application: OnboardingApplication | null) => Promise<boolean>;
    isEditable: (application: OnboardingApplication | null) => Promise<boolean>;
    isStarted: (application: OnboardingApplication | null) => Promise<boolean>;
}

export interface CollectionProps {
    application: OnboardingApplication;
    collectionId: string;
    userId: string;
}

export interface StepProps {
    application: OnboardingApplication;
    collectionId: string;
    step: Step;
    stepId: string;
    userId: string;
}

export enum CollectionStatus {
    COMPLETED = 'Completed',
    IN_PROGRESS = 'In Progress',
    NOT_STARTED = 'Not Started',
}
