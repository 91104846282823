import { ORGS } from '@keyliving/shared-types';
import { isOrg } from '@keyliving/utils';
import { Validator } from 'react-router-typesafe-routes';

import type { CollectionId, StepId } from '../../components/sequence/constants';
import isCollectionId from '../../utils/isCollectionId';
import isStepId from '../../utils/isStepId';

/**
 * This only validates that you are providing a valid collection id string to pass in the
 * url based on ALL of the collection ids across ALL organizations sequences. It does NOT
 * validate that you are providing a collection id thats in the users loaded organization
 * sequence. That is up to YOU.
 */
export const validCollectionId: Validator<CollectionId> = (value: unknown): CollectionId => {
    if (!isCollectionId(value)) {
        throw new Error('not an valid collection id');
    }

    return value;
};

/**
 * This only validates that you are providing a valid step id string to pass in the
 * url based on ALL of the step ids across ALL organizations sequences. It does NOT
 * validate that you are providing a step id thats in the users loaded organization
 * sequence. That is up to YOU.
 */
export const validStepId: Validator<StepId> = (value: unknown): StepId => {
    if (!isStepId(value)) {
        throw new Error('not an valid step id');
    }

    return value;
};

/**
 * This only validates that you are providing a valid org string
 * to pass in the url.
 */
export const validOrg: Validator<ORGS> = (value: unknown): ORGS => {
    if (typeof value !== 'string' || !isOrg(value)) {
        throw new Error('not an valid org');
    }

    return value;
};
