import { SEQUENCES } from '../components/sequence/constants';
import { Step } from '../components/sequence/types';

/**
 * Get an array of unique step ids across ALL sequences
 *
 * @returns Array of step ids
 */
export default function getUniqueStepIds(): string[] {
    const allSequences = Object.values(SEQUENCES);

    const uniqueSteps = allSequences
        .map((sequence) => Object.values(sequence))
        .reduce((accumulator, collections) => {
            const uniqueCollectionSteps = collections.reduce((accumulator, collection) => {
                return { ...accumulator, ...collection.steps };
            }, {} as Record<string, Step>);

            return { ...accumulator, ...uniqueCollectionSteps };
        }, {} as Record<string, Step>);

    return Object.keys(uniqueSteps);
}
