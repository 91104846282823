import { ORGS } from '@keyliving/shared-types';
import { isOrg } from '@keyliving/utils';
import { createContext, Fragment, lazy, LazyExoticComponent } from 'react';
import { Outlet } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';

import NoMatch from '../../../pages/NoMatch';
import { URLS } from '../../../routes/urls';
import { BrandLayoutProps } from '../../layout/BrandLayouts/types';
import { SEQUENCES } from '../constants';
import { Sequence } from '../types';

export interface SequenceContextData {
    org: ORGS;
    sequence: Sequence | null;
}

const defaultState: SequenceContextData = {
    sequence: null,
    org: ORGS.key,
};

const sequenceContext = createContext<SequenceContextData>(defaultState);

const CloverLayout = lazy(() => import('../../../components/layout/BrandLayouts/CloverLayout'));
const KeyLayout = lazy(() => import('../../../components/layout/BrandLayouts/KeyLayout'));

const lookup = new Map<ORGS, LazyExoticComponent<(props: BrandLayoutProps) => JSX.Element>>([
    [ORGS.clover, CloverLayout],
    [ORGS.key, KeyLayout],
]);

export function SequenceProvider() {
    const { applicationId, org } = useTypedParams(URLS.Collection);

    if (!org || !isOrg(org) || !applicationId) {
        return <NoMatch />;
    }

    const brandLayout = lookup.get(org);
    const Layout = brandLayout ?? Fragment;
    const sequence = SEQUENCES[org];

    if (!sequence) {
        return <NoMatch />;
    }

    return (
        <sequenceContext.Provider value={{ sequence, org }}>
            <Layout>
                <Outlet />
            </Layout>
        </sequenceContext.Provider>
    );
}

export default sequenceContext;
